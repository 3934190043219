@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background: #13111a;
}
.launchBorder {
  position: relative;
  border-radius: 50px;
  background-color: black;
}
.launchBorder::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: 0;
  bottom: 0;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  padding: 2px;
  border-radius: 50px;
  background: linear-gradient(90deg, #7353e5 -1%, #ce4da4 52%, #ce4da4 99%)
      border-box,
    white padding-box;
  z-index: -1;
}
.social-text {
  padding-bottom: 3px;
  background: linear-gradient(90deg, #ce4da4 0%, #7353e5 99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.bgBtn {
  background: linear-gradient(270deg, #cd4da6 -10%, #7353e5 104%);
}
.xian {
  background: linear-gradient(-270deg, #cd4da5 0%, #7653e4 100%), #134fff;
}
.shuXian {
  background: linear-gradient(0deg, #f87df4 -1%, #58a8fa 98%), #134fff;
}
.gradient-shadow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: linear-gradient(
    90deg,
    rgba(115, 83, 229, 0.5),
    rgba(206, 77, 164, 0.5)
  );
  z-index: -1;
  filter: blur(15px);
  transform: scale(1.1);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.gradient-shadow:hover::before {
  opacity: 0.5;
}
.gradient-shadowYuan::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: linear-gradient(
    90deg,
    rgba(115, 83, 229, 0.5),
    rgba(206, 77, 164, 0.5)
  );
  z-index: -10;
  filter: blur(15px);
  transform: scale(1.1);
  transition: opacity 0.3s ease-in-out;
}
.yuan {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: radial-gradient(
    99% 70% at 50% 50%,
    #c77aff 0%,
    #c77aff 26%,
    #01ffe4 100%
  );
}
.xzYuan {
  background: radial-gradient(99% 70% at 50% 50%, #ff69f9 0%, #1c00ff 100%);
}
.ant-steps-item-icon {
  margin-left: -6px !important;
  margin-top: 7px !important;
}
.ant-steps-item-content {
  margin-left: 30px;
}
/* 定义滚动条样式 */
::-webkit-scrollbar {
  width: 2px; /* 滚动条宽度 */
  height: 2px; /* 滚动条高度（适用于水平滚动条） */
}

::-webkit-scrollbar-track {
  background: ""; /* 滚动条轨道背景 */
  border-radius: 6px; /* 圆角 */
}

::-webkit-scrollbar-thumb {
  background: #888; /* 滚动条颜色 */
  border-radius: 6px; /* 圆角 */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* 滚动条颜色在悬停状态下 */
}
/* 针对 WebKit 浏览器，使滚动条默认不可见 */
::-webkit-scrollbar {
  display: none;
}

/* 当容器滚动时，使用 JavaScript 动态添加滚动条样式 */
:hover::-webkit-scrollbar {
  display: block;
}
